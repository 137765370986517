<script lang="ts">
import { ComponentOptionsBase, h } from 'vue';
import { setLocaleToLink } from '@/utils/set-locale-to-link';

const BaseLink: ComponentOptionsBase<any, any, any, any, any, any, any, any> = {
  name: 'BaseLink',
  props: {
    tag: {
      type: String,
      default: 'a',
    },
    img: { type: String, default: '' },
    href: {
      type: [String, Object],
      default: undefined,
    },
    target: {
      type: String,
      default: '',
    },
  },
  computed: {
    isSite(): boolean {
      return !!this.$store.getters.isSite;
    },
  },
  render() {
    let newProps = {};
    let children: ReturnType<typeof h>[] = [];

    if (this.img) {
      children = [
        h('img', {
          class: {
            'base-link__image': true,
          },
          src: this.img,
        }),
      ];
    }

    const { href } = this;

    newProps = {
      ...this.$attrs,
      href: setLocaleToLink(href, this.$store.getters.currentLocaleISO, this.isSite),
      target: this.target,
    };
    return h(h(this.tag || 'a', newProps), [...children, this.$slots.default()]);
  },
};

export default BaseLink;
</script>
<style lang="scss">
.base-link__image {
  display: inline-block;
  width: 20px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
}
</style>
