export function setLocaleToLink(href: string, locale: string, isSite: boolean) {
  if (!href) {
    return '';
  }

  if (!isSite) {
    return href;
  }
  // На международке у нас могут быть ссылки с кз на ру, с ру на кз или еще куда-нибудь.
  // Все, что написано в следующих репозиториях ожидает текующую локаль.
  // Т.е если мы на кз, и видим ссылку /ru/smth, то результатом будет /kz/ru/smth
  // Я не знаю как нам декетить локаль кроме как ожидать что два символа завернутые в слеши в начале
  // строки - это префикс локали
  if (/^(\/\w\w\/)/.test(href)) {
    return href;
  }

  // Если внешняя ссылка ничего не длаем
  if (href.startsWith('http')) {
    return href;
  }

  if (href === '/') {
    return `/${locale}`;
  }

  if (href.startsWith(`/${locale}/`) || href === `/${locale}`) {
    return href;
  }

  if (href.startsWith('/')) {
    return `/${locale}${href}`;
  }

  return `/${locale}/${href}`;
}
